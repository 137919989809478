<template>

  <div class="w-100">
    <b-card class="w-100"
    v-if="joke">
      <table class="table table-borderless">
      <thead class="thead-success">
        <tr class="font-weight-bolder-header">
          <th scope="col">Name</th>
          <th scope="col">Symbol</th>
          <th scope="col">% Change</th>
          <th scope="col">Share-Quantity</th>
          <th scope="col"></th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(item) in companyTable" :key="item.id">
          <td>{{ item.Name }}</td>
          <td> {{ item.Ticker }}</td>
          <td><b-badge variant="light-success">
            {{ nFormatter(item.Shares) }}
          </b-badge></td>
          <td>
            <b-form-spinbutton
              ref="quantityInput"
              id="demo-sb"
              v-model="item.Quantity"
              min="1"
              max="100"
              @input="handleQuantityInput"
            /></td>
          <td><b-button class="align-middle"
                id="popover-reactive-1"
                variant="danger"
                @click="confirmText(item.Name)"
              >
                <feather-icon
                  icon="Trash2Icon"
                  class="align-middle"
                />
                
              </b-button></td>
              <td>
                 <b-button class="align-middle"
                id="popover-reactive-1"
                variant="outline-primary"
                @click="ChangeQuantity(item.Name, item.Quantity),showToast(item.Name)"
              >Save
                <feather-icon
                  icon="BookmarkIcon"
                  class="align-left"
                />
                
              </b-button></td>
        </tr>
      </tbody>
    </table> 
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BTable, BAvatar, BImg, BBadge,BButton, BFormSpinbutton
} from 'bootstrap-vue'
import { methods } from 'vue-echarts';
import Ripple from 'vue-ripple-directive'
import { doc, updateDoc, arrayUnion, arrayRemove,getDoc } from "firebase/firestore";
import { db } from '@/firebase/index'
import { getAuth,onAuthStateChanged  } from "firebase/auth";
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'



export default {
  components: {
    BCard,
    BTable,
    BAvatar,
    BImg,
    BBadge,
    BButton,
    BFormSpinbutton
  },
  computed: {
    joke() {
      return this.$store.getters.getPortfolioValues
    },
    companyTable() { 
      return this.companyTable = this.joke
    }
  },
  directives:{
    Ripple,
  },
  data() {
    return {  
      inputTimer: null,
    }
  },
  methods: {
    handleQuantityInput(newValue) {
      // Clear previous timer
      clearTimeout(this.inputTimer);

      // Set a new timer to update the value after a short delay (e.g., 500 milliseconds)
      this.inputTimer = setTimeout(() => {
        // Update the quantity value in your data or perform any other actions as needed
        // For example, you can update the value in the companyTable array:
        const index = this.companyTable.findIndex((item) => item.Name === this.item.Name);
        if (index !== -1) {
          this.$set(this.companyTable, index, { ...this.item, Quantity: newValue });
        }
      }, 500); // Adjust the delay as needed
    },
    nFormatter(num) {
     if (num >= 1000000000) {
        return (num / 1000000000).toFixed(1).replace(/\.0$/, '') + 'B';
     }
     if (num >= 1000000) {
        return (num / 1000000).toFixed(1).replace(/\.0$/, '') + 'M';
     }
     if (num >= 1000) {
        return (num / 1000).toFixed(1).replace(/\.0$/, '') + 'K';
     }
     return num;
    },
    async RemoveCompany(CompName) {
          const auth = getAuth();
          const user =  await auth.currentUser.uid;
          const docRef = doc(db, "users", user);
          const docSnap = await getDoc(docRef);
            if (docSnap.exists()) {
                console.log("Document data:", docSnap.data().Portfolio);
                const data =  docSnap.data().Portfolio
                const objIndex = data.findIndex((obj => obj.Name == CompName));
                const Company = data[objIndex]
                await updateDoc(doc(db,"users",user),({ Portfolio: arrayRemove({BuyPrice: Company.BuyPrice, Name: Company.Name,Quantity: Company.Quantity})}))
            }
          const store = this.$store.getters.getPortfolioValues
          const newStore = store.filter(function(el) { return el.Name != CompName; });
          this.$store.dispatch('setPortfolioValues',newStore)

    },
    async ChangeQuantity(CompName,Quantity) {
      const auth = getAuth();
      const user =  await auth.currentUser.uid;
      const docRef = doc(db, "users", user);
      const docSnap = await getDoc(docRef);
            if (docSnap.exists()) {
                console.log("Document data:", docSnap.data().Portfolio);
                const data =  docSnap.data().Portfolio
                const objIndex = data.findIndex((obj => obj.Name == CompName));
                const Company = data[objIndex]
                await updateDoc(doc(db,"users",user),({ Portfolio: arrayRemove({BuyPrice: Company.BuyPrice, Name: Company.Name,Quantity: Company.Quantity})}))
                await updateDoc(doc(db,"users",user),({ Portfolio: arrayUnion({Name: CompName, Quantity: Quantity, BuyPrice: Company.BuyPrice})}))
            }        
    },
    confirmText(Name) {
      this.$swal({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.RemoveCompany(Name)
          this.$swal({
            icon: 'success',
            title: 'Deleted!',
            text: `${Name} successfully deleted`,
            customClass: {
              confirmButton: 'btn btn-success',
            },
          })
        }
      })
    },
    showToast(Name) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Portfolio',
          icon:"PieChartIcon",
          text: `Your position in ${Name} has been updated`,
          variant:"primary",
        },
      })
    },
  }}
</script>

<style lang="scss" scoped>
@import '~@core/scss/base/bootstrap-extended/include';
@import '~@core/scss/base/components/variables-dark';

.card-company-table ::v-deep td .b-avatar.badge-light-company {
  .dark-layout & {
    background: $theme-dark-body-bg !important;
  }
}
</style>
