<template>
    <div>
    <b-row class="match-height">
        <b-col lg="8">
            <portfolio-price-value-chart-vue lg="8" />
        </b-col>
        <b-col lg="4">
            <Stats />
        </b-col>
    </b-row>
    <b-row class="match-height">
        <b-col  class="match-height" lg="4">
            <portfolio-chart-vue />
        </b-col>  
        <b-col class="match-height" lg="8">
            <Table />
        </b-col>
    </b-row>
</div>
</template>

<script>
import PortfolioPriceValueChartVue from "./PortfolioPriceValueChart.vue"
import PortfolioChartVue from "./PortfolioChart.vue"
import Table from "./Table.vue"
import { BRow,BCol } from "bootstrap-vue"
import Stats from "./Stats.vue"
import { doc, getDoc,collection,query,where,documentId, getDocs } from "firebase/firestore";
import { getAuth,onAuthStateChanged  } from "firebase/auth";
import { db } from '@/firebase/index'


export default{
    components:{
        PortfolioPriceValueChartVue,
        PortfolioChartVue,
        BRow,
        BCol,
        Table,
        Stats,

    },
    created() {
        this.Portfolio =  Object.values(this.getPortfolio());
        this.getPort()
    },
    methods: {
        async getPortfolio() {
            const auth = getAuth();
            const user = auth.currentUser.uid;
            const docRef = doc(db, "users", user);
            const docSnap = await getDoc(docRef);

            if (docSnap.exists()) {
                // console.log("Document data:", docSnap.data().Folio);
                const data = docSnap.data().Folio
                this.$store.dispatch('setCurrentPortfolio',data)
            } else {
            // doc.data() will be undefined in this case
                console.log("No such document!");
            }
        },
        async getPort() {
            const values = []
            const auth = getAuth();
            const user =  await auth.currentUser.uid;
            const docRef = doc(db, "users", user);
            const docSnap = await getDoc(docRef);
            if (docSnap.data().Portfolio !== undefined) {
                console.log("Document data:", docSnap.data().Portfolio);
                const data =  docSnap.data().Portfolio
                this.Names = data.map(item => item.Name)
                this.Quantity = data.map(item => item.Quantity)
                this.BuyPrice = data.map(item => item.BuyPrice)
                this.Prices =data.map(item => item.Prices)
                const QuantityObject = {}
                data.forEach(item => QuantityObject[item.Name]= item.Quantity)
                const Prices = {}  
                data.forEach(item => Prices[item.Name]= item.Prices)
                const companyArray =  data.map(item => item.Name)
            //    console.log(companyArray)
                const ocRef= collection(db, "companies")
                const q = query(ocRef, where(documentId(), 'in', companyArray));
                const querySnapshot = await getDocs(q)
                querySnapshot.forEach((doc) => {
                    const Ticker = doc.data().Ticker
                    // doc.data() is never undefined for query doc snapshots
                    const DividendYield = Object.values(doc.data().DividendYield).pop()
                    const Prices = doc.data().Prices
                    console.log("Preise=",Prices)
                    // console.log(DividendYield)
                    const data = {"Name":doc.id, "DividendYield": DividendYield, "Quantity": QuantityObject[doc.id], "Prices": Prices, "Ticker":Ticker}
                    // console.log(data)
                    values.push(data)
                });
                const LastValues  =  values.map((item, i) => ({...item, BuyPrice: this.BuyPrice[i]}));
                // const LastLastValues = LastValues.map((item, i) => ({...item, Prices: this.Prices[i]}));
                console.log(LastValues)
                //   console.log(values)
                this.$store.dispatch('setBackTestValues',LastValues)
            } else {
                this.EmptyPortfolio()
            }
            },
        withTitle() {
            this.$swal({
                title: 'The Internet?,',
                text: 'That thing is still around?',
                customClass: {
                confirmButton: 'btn btn-primary',
                },
                buttonsStyling: false,
            })
        },
        EmptyPortfolio() {
            this.$swal({
                title: 'Nothing here ?,',
                text: 'Add some companies in the screener or Company Analysis section and come back :)',
                customClass: {
                confirmButton: 'btn btn-primary',
                },
                buttonsStyling: false,
            }).then(result => {
                if (result.value) {
                this.$router.push('companyanalysis')
                }
            })
    },
            
    }

}
</script>


<style lang="scss">
    @import '@core/scss/vue/pages/dashboard-ecommerce.scss';
    @import '@core/scss/vue/libs/chart-apex.scss';
 </style>