<template>
    <b-card>
      <b-tabs
      >
        <b-tab lazy>
          <template #title>
            <feather-icon icon="PieChartIcon" />
            <span>Portfolio</span>
          </template>
  
          <portfolio />
  
        </b-tab>
        <b-tab lazy disabled>
          <template #title>
            <feather-icon icon="ClockIcon" />
            <span>Back-Test</span>
          </template>
  
          <back-test-vue />
  
        </b-tab>
      </b-tabs>
    </b-card>
  </template>
  
  <script>
  import BCardCode from '@core/components/b-card-code'
  import { BTabs, BTab } from 'bootstrap-vue'
import Portfolio from './Portfolio/Portfolio.vue'
import BackTestVue from './BackTest/BackTest.vue'

  
  export default {
    components: {
      BCardCode,
      BTabs,
      BTab,
        Portfolio,
        BackTestVue
    },

  }
  </script>
  