<template>
    <div>
    <b-row class="match-height">
        <b-col lg="8">
            <portfolio-price-value-chart-vue lg="8" />
        </b-col>
        <b-col lg="4">
            <Stats />
        </b-col>
    </b-row>
    <b-row class="match-height">
        <b-col  class="match-height" lg="4">
            <portfolio-chart-vue />
        </b-col>  
        <b-col class="match-height" lg="8">
            <Table />
        </b-col>
    </b-row>
    <b-modal
      class="rounded-header"
      id="modal-login"
      cancel-variant="outline-danger"
      ok-variant="info"
      v-model="showLoginModal"
      ok-title="Login"
      cancel-title="Close"
      centered
      title="You need to Be Signed up for access"
      size="md"
      @ok="firelogin()"
      @cancel="handleCancel()"
      @hide="handleCancel()"
    >
      <b-form>
        <b-form-group class="rounded-header" >
          <b-form-input
            id="email"
            type="email"
            placeholder="Email Address"
            class="rounded-input"
            v-model="userEmail"
          />
        </b-form-group>
        <b-form-group class="rounded-header" >
          <b-form-input
            id="password"
            type="password"
            placeholder="Password"
            class="rounded-input"
            v-model="password"
          />
        </b-form-group>
        <div class="text-center">
        <hr>
        <span class="">OR</span>
        <hr >
      </div>

        <button type="button" class="google-sign-in-button btn btn-outline-secondary text-center btn-block" @click="GoogleSignIn">
              Sign In with Google
            </button>
      </b-form>
    </b-modal>
</div>

</template>

<script>
import PortfolioPriceValueChartVue from "./PortfolioPriceValueChart.vue"
import PortfolioChartVue from "./PortfolioChart.vue"
import Table from "./Table.vue"
import { BRow,BCol,BModal, VBModal,BForm, BFormInput, BFormGroup,  } from "bootstrap-vue"
import Stats from "./Stats.vue"
import { doc, getDoc,collection,query,where,documentId, getDocs } from "firebase/firestore";
import { getAuth,onAuthStateChanged  } from "firebase/auth";
import { db } from '@/firebase/index'
import {  signInWithEmailAndPassword,createUserWithEmailAndPassword,GoogleAuthProvider, signInWithPopup  } from "firebase/auth";
import { Ref } from 'vue-demi'
import useJwt from '@/auth/jwt/useJwt'



export default{
    components:{
        PortfolioPriceValueChartVue,
        PortfolioChartVue,
        BRow,
        BCol,
        Table,
        Stats,
        BModal, 
        VBModal,
        BForm, 
        BFormInput, 
        BFormGroup,

    },
    data() {
    return {  
      showLoginModal: false,
      password: '',
      userEmail: '',
        }
    },
    created() {
        this.Portfolio =  Object.values(this.getPortfolio());
        this.getPort()
        this.loggedIn()
    },
    methods: {
      async firelogin() {
  console.log('FIIIIIIIIIIRE');
  const auth = getAuth();

  // Lokale Kopien der Anmeldedaten speichern
  const email = this.userEmail;
  const password = this.password;
  // Generiere den Benutzernamen aus der E-Mail-Adresse
  const username = email.split('@')[0];

  try {
    // Versuche den Benutzer anzumelden
    const userCredential = await signInWithEmailAndPassword(auth, email, password);
    const user = userCredential.user;

    // Überprüfe den Authentifizierungszustand
    onAuthStateChanged(auth, async (user) => {
      if (user) {
        console.log(user.uid);
        const uid = user.uid;
        const docRef = doc(db, "users", uid);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          const userData = docSnap.data().userData;
          localStorage.setItem('userData', JSON.stringify(userData));
          this.$ability.update(userData.ability);
          this.$bvModal.hide('modal-login3');
          window.location.reload(); // Seite neu laden
        } else {
          console.log("No such document!");
        }
      }
    });
  } catch (error) {
    // Wenn Benutzer nicht existiert, erstelle einen neuen Benutzer
    console.log('User nicht vorhanden, wird jetzt registriert');

    try {
      // Überprüfe, ob `email` und `password` definiert sind
      if (!email || !password) {
        console.error('Fehlende Eingabedaten:', {
          username: username,
          email: email,
          password: password
        });
        throw new Error('Username, Email oder Passwort ist nicht definiert');
      }

      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;

      // Speichere die Benutzerdaten in der Firestore-Datenbank
      await setDoc(doc(db, "users", user.uid), {
        username: username,
        email: email,
        tutorial: false,
        role: "user",
        userData: {
          id: 2,
          fullName: username,
          username: username,
          password: 'client',
          avatar: '',
          email: email,
          role: 'client',
          ability: [
            { action: 'read', subject: 'ACL' },
            { action: 'read', subject: 'Auth' },
          ],
        },
      });

      // Registrierung erfolgreich, führe den Rest aus
      const response = await useJwt.register({
        username: username,
        email: email,
        password: password,
      });

      useJwt.setToken(response.data.accessToken);
      useJwt.setRefreshToken(response.data.refreshToken);
      localStorage.setItem('userData', JSON.stringify(response.data.userData));
      this.$ability.update(response.data.userData.ability);
      this.$router.push('/companyanalysis');
      window.location.reload(); // Seite neu laden

    } catch (registrationError) {
      console.error("Fehler bei der Registrierung: ", registrationError);
    }
  }
},
    async GoogleSignIn() {
      const auth = getAuth();
  const provider = new GoogleAuthProvider();

  // Prompt user to select Google account for sign-in
  signInWithPopup(auth, provider)
    .then((result) => {
      // The signed-in user info
      const user = result.user;

      // Check if user document already exists
      const userDocRef = doc(db, "users", user.uid);
      getDoc(userDocRef)
        .then((docSnapshot) => {
          if (!docSnapshot.exists()) {
            // Store user details in Firestore
            setDoc(userDocRef, {
              username: user.displayName,
              email: user.email,
              tutorial: false,
              FreeTrial: 0,
              role: "user",
              userData: {
                id: 2,
                fullName: user.displayName,
                username: user.displayName,
                password: 'client',
                avatar: '', // You can update this with user's profile picture if available
                email: user.email,
                role: 'client',
                ability: [
                  {
                    action: 'read',
                    subject: 'ACL',
                  },
                  {
                    action: 'read',
                    subject: 'Auth',
                  },
                ],
              },
            });
          }

          // Proceed with registration
          useJwt.register({
            username: user.displayName,
            email: user.email,
            password: 'dummyPassword', // Dummy password?
          })
          .then(response => {
            // Handle successful registration
            useJwt.setToken(response.data.accessToken)
            useJwt.setRefreshToken(response.data.refreshToken)
            localStorage.setItem('userData', JSON.stringify(response.data.userData))
            this.$ability.update(response.data.userData.ability)
            this.$bvModal.hide('modal-login3');
            window.location.reload();
            // Move navigation here
          })
          .catch(error => {
            // Handle registration errors
            console.error("Error during registration:", error);
          });
        })
        .catch((error) => {
          // Handle Firestore query error
          console.error("Error querying Firestore:", error);
        });
    })
    .catch((error) => {
      // Handle errors here
      console.error("Error during Google registration:", error);
    });
  },
    handleCancel() {
    this.$router.push('/companyanalysis'); // Ändere '/desired-page' auf die gewünschte Zielseite
  },
        async getPortfolio() {
            const auth = getAuth();
            const user = auth.currentUser.uid;
            const docRef = doc(db, "users", user);
            const docSnap = await getDoc(docRef);

            if (docSnap.exists()) {
                // console.log("Document data:", docSnap.data().Folio);
                const data = docSnap.data().Folio
                this.$store.dispatch('setCurrentPortfolio',data)
            } else {
            // doc.data() will be undefined in this case
                console.log("No such document!");
            }
        },
        loggedIn() {
            const auth = getAuth();
            onAuthStateChanged(auth, async (user) => {
                if (user) {
        }else {
            this.showLoginModal = true;
        }})},
        async getPort() {
            const values = []
            const auth = getAuth();
            const user =  await auth.currentUser.uid;
            const docRef = doc(db, "users", user);
            const docSnap = await getDoc(docRef);
            if (docSnap.data().Portfolio !== undefined) {
                console.log("Document data:", docSnap.data().Portfolio);
                const data =  docSnap.data().Portfolio
                this.Names = data.map(item => item.Name)
                this.Quantity = data.map(item => item.Quantity)
                this.BuyPrice = data.map(item => item.BuyPrice)
                this.Prices =data.map(item => item.Prices)
                const QuantityObject = {}
                data.forEach(item => QuantityObject[item.Name]= item.Quantity)
                const Prices = {}  
                data.forEach(item => Prices[item.Name]= item.Prices)
                const companyArray =  data.map(item => item.Name)
            //    console.log(companyArray)
                const ocRef= collection(db, "companies")
                const q = query(ocRef, where(documentId(), 'in', companyArray));
                const querySnapshot = await getDocs(q)
                querySnapshot.forEach((doc) => {
                    const Ticker = doc.data().Ticker
                    // doc.data() is never undefined for query doc snapshots
                    const DividendYield = Object.values(doc.data().DividendYield).pop()
                    const Prices = Object.values(doc.data().Prices)
                    // console.log(DividendYield)
                    const data = {"Name":doc.id, "DividendYield": DividendYield, "Quantity": QuantityObject[doc.id], "Prices": Prices, "Ticker":Ticker}
                    // console.log(data)
                    values.push(data)
                });
                const LastValues  =  values.map((item, i) => ({...item, BuyPrice: this.BuyPrice[i]}));
                // const LastLastValues = LastValues.map((item, i) => ({...item, Prices: this.Prices[i]}));
                console.log(LastValues)
                //   console.log(values)
                this.$store.dispatch('setPortfolioValues',LastValues)
            } else {
                this.EmptyPortfolio()
            }
            },
        withTitle() {
            this.$swal({
                title: 'The Internet?,',
                text: 'That thing is still around?',
                customClass: {
                confirmButton: 'btn btn-primary',
                },
                buttonsStyling: false,
            })
        },
        EmptyPortfolio() {
            this.$swal({
                title: 'Nothing here ?,',
                text: 'Add some companies in the screener or Company Analysis section and come back :)',
                customClass: {
                confirmButton: 'btn btn-primary',
                },
                buttonsStyling: false,
            }).then(result => {
                if (result.value) {
                this.$router.push('companyanalysis')
                }
            })
    },
            
    }

}
</script>


<style lang="scss">
    @import '@core/scss/vue/pages/dashboard-ecommerce.scss';
    @import '@core/scss/vue/libs/chart-apex.scss';
    .google-sign-in-button {
  cursor: pointer;
  transition: background-color .3s, box-shadow .3s;
  padding: 20px 16px; /* Adjusted padding */
  border: none;
  border-radius: 10px;
  color: #FFFFFF;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTgiIGhlaWdodD0iMTgiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGcgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj48cGF0aCBkPSJNMTcuNiA5LjJsLS4xLTEuOEg5djMuNGg0LjhDMTMuNiAxMiAxMyAxMyAxMiAxMy42djIuMmgzYTguOCA4LjggMCAwIDAgMi42LTYuNnoiIGZpbGw9IiM0Mjg1RjQiIGZpbGwtcnVsZT0ibm9uemVybyIvPjxwYXRoIGQ9Ik05IDE4YzIuNCAwIDQuNS0uOCA2LTIuMmwtMy0yLjJhNS40IDUuNCAwIDAgMS04LTIuOUgxVjEzYTkgOSAwIDAgMCA4IDV6IiBmaWxsPSIjMzRBODUzIiBmaWxsLXJ1bGU9Im5vbnplcm8iLz48cGF0aCBkPSJNNCAxMC43YTUuNCA1LjQgMCAwIDEgMC0zLjRWNUgxYTkgOSAwIDAgMCAwIDhsMy0yLjN6IiBmaWxsPSIjRkJCQzA1IiBmaWxsLXJ1bGU9Im5vbnplcm8iLz48cGF0aCBkPSJNOSAzLjZjMS4zIDAgMi41LjQgMy40IDEuM0wxNSAyLjNBOSA5IDAgMCAwIDEgNWwzIDIuNGE1LjQgNS40IDAgMCAxIDUtMy43eiIgZmlsbD0iI0VBNDMzNSIgZmlsbC1ydWxlPSJub256ZXJvIi8+PHBhdGggZD0iTTAgMGgxOHYxOEgweiIvPjwvZz48L3N2Zz4=);
  background-repeat: no-repeat;
  background-position: 90px 50%; /* Adjusted background position */

}

.google-sign-in-button:hover {
  box-shadow: 0 -1px 0 rgba(0, 0, 0, .04), 0 2px 4px rgba(0, 0, 0, .25);
}

.google-sign-in-button:active {
  background-color: #eeeeee;
  outline: none;
  box-shadow: 
    0 -1px 0 rgba(0, 0, 0, .04),
    0 2px 4px rgba(0, 0, 0, .25),
    0 0 0 3px #c8dafc;
}


.b-button-google:hover {
  border-color: #2d3748;
  color: #1a202c;
  background-color: #edf2f7;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}
 </style>