<template>
    <b-card>
        <b-card-title class="mb-10">
           <h4>
            Statistics
           </h4>
        </b-card-title>
        <b-card-subtitle>
    <b-row>
        <b-col>
            <h6 class="text-left" style="color: #6c757d; font-size: 0.9rem;">Your Portfolio Value</h6>
        </b-col>
        <b-col>
            <h3 class="text-right" style="color: #007bff; font-size: 1.2rem;">{{ this.PortfolioValue + ' $' }}</h3>
        </b-col>
    </b-row>
</b-card-subtitle>

<b-card-body>
    <b-card class="border">
        <b-row class="mb-10">
            <b-col>
                <h5 class="text-right" style="color: #6c757d; font-size: 0.9rem;">Market Return</h5>
            </b-col>
            <b-col>
                <h3 class="text-right" style="color: #28CD41; font-size: 1.2rem;">
                    {{ Math.round(this.MarketYtd * 10) / 10 + '%' }}
                </h3>
            </b-col>
        </b-row>
        <b-row class="mb-10">
            <b-col>
                <h5 class="text-right" style="color: #6c757d; font-size: 0.9rem;">Return this Year</h5>
            </b-col>
            <b-col>
                <h3 class="text-right" style="color: #007bff; font-size: 1.2rem;">{{ this.Performance }}</h3>
            </b-col>
        </b-row>
        <b-row class="mb-10">
            <b-col>
                <h5 class="text-right" style="color: #6c757d; font-size: 0.9rem;">Against the Market Return</h5>
            </b-col>
            <b-col>
                <h3 class="text-right" style="color: #FF9500; font-size: 1.2rem;">
                    {{ Math.round((this.Performance - this.MarketYtd) * 10) / 10 }}
                </h3>
            </b-col>
        </b-row>
    </b-card>
    
    <b-card class="border">
        <b-row class="">
            <b-col>
                <h5 class="text-right" style="color: #6c757d; font-size: 0.9rem;">Market Dividend-Yeld</h5>
            </b-col>
            <b-col>
                <h3 class="text-right" style="color: #28CD41; font-size: 1.2rem;">
                    {{ this.MarketDividends.toFixed(1) + '%' }}
                </h3>
            </b-col>
        </b-row>
        <b-row class="">
            <b-col>
                <h5 class="text-right" style="color: #6c757d; font-size: 0.9rem;">Dividends this year</h5>
            </b-col>
            <b-col>
                <h3 class="text-right" style="color: #007bff; font-size: 1.2rem;">
                    {{ this.Dividends.toFixed(1) + '%' }}
                </h3>
            </b-col>
        </b-row>
        <b-row class="">
            <b-col>
                <h6 class="text-right" style="color: #6c757d; font-size: 0.9rem;">Dividends against the market</h6>
            </b-col>
            <b-col>
                <h3 class="text-right" style="color: #FF9500; font-size: 1.2rem;">
                    {{ Math.round((this.Dividends - this.MarketDividends) * 10) / 10 + '%' }}
                </h3>
            </b-col>
        </b-row>
    </b-card>
</b-card-body>

    </b-card>
</template>

<script>
import { BCard, BRow, BCol } from 'bootstrap-vue';
import StatisticCardHorizontal from '@core/components/statistics-cards/StatisticCardHorizontal.vue'
import { BBadge } from 'bootstrap-vue'
import { doc, snapshot, getDoc, documentId,query,collection,where, getDocs,updateDoc, arrayRemove, FieldValue,deleteField } from "firebase/firestore";
import { getAuth,onAuthStateChanged  } from "firebase/auth";
import { db } from '@/firebase/index'



export default {
    components:{
        BCard,
        BRow,
        BCol,
        StatisticCardHorizontal,
        BBadge
    }, 
    computed: {
        Values() {
            return this.$store.getters.getPortfolioValues
        },
        QuantityPortfolio() {
            return this.$store.getters.getPortfolioValues.reduce( function(a, b){
                 return a + b['Quantity'];
                }, 0);
        },
        Dividends() {
            const sum = this.Portfolio.map(item => item.DividendYield*item.Quantity/this.QuantityPortfolio).reduce(function(pv, cv) { return pv + cv; }, 0);
             return Math.round(sum * 100)/10
        },
        Portfolio () {
            return this.$store.getters.getPortfolioValues
        },
        PortfolioValue() {
            const sum = this.Portfolio.map(item => Object.values(item.Prices).pop()*item.Quantity).reduce(function(pv, cv) { return pv + cv; }, 0);
            return sum
        },
        MarketDividends() {
            return this.$store.getters.getMarketData.DividendYield
        },
        MarketYtd() {
            return this.$store.getters.getMarketData['Ytd-Performance']*100
        },
        Prices(){
            return this.Values.map(item => ((item.Prices)))
        },
        Quantity() {
            return this.Values.map(item => item.Quantity)
        },
        Performance() {
        if (this.Values === undefined) {
          return this.series = [{data: [ 2,1,13,8,7]}]
        }
        const series = []
        const Quantity = this.Quantity
        // this.Prices.forEach(function callback(element, i) {series.push({data: (Object.values(element).map(item => (item*Quantity[i]/QuantityPortfolio)))})});
        // this.Prices.forEach(function callback(element, i) {series.push( (Object.values(element).map(item => (item*Quantity[i]))))});
        // const sums = series[0].map((x, idx) => series.reduce((sum, curr) => sum + curr[idx], 0));
        // const Performance =  Math.round(((sums.pop()-sums.at(-1))/sums.at(-1))*1000)/10
        // return Performance
      }
        
    },
    created() {
        // this.getPortfolioValues();
        this.getMarktetData();
    },
    methods:{
        // async getPortfolioValues() {
        //     const values = []
        //    const companyArray =  this.$store.getters.getCurrentPortfolio.map(item => item.Name)
        // //    console.log(companyArray)
        //    const ocRef= collection(db, "companies")
        //    const q = query(ocRef, where(documentId(), 'in', companyArray));
        //    const querySnapshot = await getDocs(q)
        //    querySnapshot.forEach((doc) => {
        //     // doc.data() is never undefined for query doc snapshots
        //     const DividendYield = Object.values(doc.data().DividendYield).pop()
        //     // console.log(DividendYield)
        //     const data = {"Name":doc.id, "DividendYield": DividendYield}
        //     // console.log(data)
        //     values.push(data)
        //   });
        // //   console.log(values)
        //   this.$store.dispatch('setPortfolioValues',values)
        // },
        async getMarktetData() {

            const docSnap = await getDoc(doc(db, 'companies', 'SPDR S&P 500'))
            if (docSnap.exists()) {
                // assign document fields
                // to data properties
                const data = docSnap.data()
                // console.log(data)
                this.$store.dispatch('setMarketData',data)
                } else {
                    console.log('Document does not exist')
                }
            },
    }
}
</script>

<style>

</style>