<template>
    <b-card>
      <b-card-title class="mb-1">
        Portfolio Composition
      </b-card-title>
      <b-card-sub-title class="mb-2">
        Categoriesed based on market value
      </b-card-sub-title>
  
      <vue-apex-charts
        type="pie"
        height="350"
        :options="this.chartOptions.chartOptions"
        :series="this.series"
      />
    </b-card>
  </template>
  
  <script>
  import {
    BCard, BCardTitle, BCardSubTitle,
  } from 'bootstrap-vue'
  import VueApexCharts from 'vue-apexcharts'
  import { $themeColors } from '@themeConfig'
  import { map } from 'rxjs/operators';
 
  
  export default {
    components: {
      VueApexCharts,
      BCard,
      BCardTitle,
      BCardSubTitle,
      map
    },
    created(){
      console.log(this.lables)
    },
    computed: {
      Portfolio() {
        return this.$store.getters.getPortfolioValues
      },
      series() {
        if (this.Portfolio === undefined) {
          return [1,1,1,1]
        }
      return this.Portfolio.map(item => item.Quantity*item.BuyPrice)
      },
      chartOptions() {
        return {
          chartOptions: {
            chart: {
              width: 380,
              type: 'pie',
            },
            labels: this.Portfolio.map(item => item.Name),
            colors: ["#5e5ce6","#6e6ce8","#7e7ceb","#8e8ced","#9e9df0","#aeadf2","#bebdf5","#cecef7","#dedefa","#eeeefc","#ffffff"],
            
            legend: {
                show: false,
        }     
          }
        }
      }
    },
    mounted() {
    },
    data() {
      return {
          donutChart: {
          chartOptions: {
            chart: {
              width: 380,
              type: 'pie',
            },
            labels: ["A","B","C"],
            colors: ["#5e5ce6","#6e6ce8","#7e7ceb","#8e8ced","#9e9df0","#aeadf2","#bebdf5","#cecef7","#dedefa","#eeeefc","#ffffff"],
            
            legend: {
                show: false,
        }     
          }}}       
  }
}
  </script>
  