<template>
     <statistic-card-with-area-chart       
          color="info"
          statistic-title="Your Portfollio"
          :chart-data="this.series"
        />
  </template>
  
  <script>
  import {
    BCard, BRow, BCol, BDropdown, BDropdownItem, BButton,
  } from 'bootstrap-vue'
  import VueApexCharts from 'vue-apexcharts'
  import { $themeColors } from '@themeConfig'
  import Ripple from 'vue-ripple-directive'
  import { methods } from 'vue-echarts'
  import StatisticCardWithAreaChart from '@core/components/statistics-cards/StatisticCardWithAreaChart.vue'
import { kFormatter } from '@core/utils/filter'
  
  export default {
    components: {
      VueApexCharts,
      BDropdown,
      BDropdownItem,
      BCard,
      BButton,
      BRow,
      BCol,
      StatisticCardWithAreaChart
    },
    methods: {
      kFormatter
    },
    directives: {
      Ripple,
    },
    computed: {
      joke() {
        return this.$store.getters.getPortfolioValues
      },
      Prices(){
        return this.joke.map(item => item.Prices)
      },
      Quantity() {
        return this.joke.map(item => item.Quantity)
      },
      // QuantityPortfolio() {
      //       return this.Quantity.reduce( function(a, b){
      //            return a + b;
      //           }, 0);
      //   },
      series() {
        if (this.Prices === undefined) {
          return this.series = [{data: [0,0,0,0]}]
        }
        const series = []
        const Quantity = this.Quantity
        // this.Prices.forEach(function callback(element, i) {series.push({data: (Object.values(element).map(item => (item*Quantity[i]/QuantityPortfolio)))})});
        this.Prices.forEach(function callback(element, i) {series.push( (Object.values(element).map(item => (item*Quantity[i]))))})
        const sums = series[0].map((x, idx) => series.reduce((sum, curr) => sum + curr[idx], 0));  
        return [{data:sums}]
        
      }
    },
    data() {
      return {
        revenue_report: {},
        revenueReport: {
          chartOptions: {
            chart: {
              type: 'pie',
              toolbar: { show: false },
            },
            grid: {
              // padding: {
              //   top: -20,
              //   bottom: -10,
              // },
              yaxis: {
                lines: { show: false },
              },
            },
            
            xaxis: {
              show: false,
              categories: ['2012','2013','2014', '2015', '2016', '2017', '2018', '2019', '2020', '2021', 'Current'],
              labels: {
                show:false,
                style: {
                  colors: '#6E6B7B',
                  fontSize: '0.86rem',
                  fontFamily: 'Montserrat',
                },
              },
              axisTicks: {
                show: false,
              },
              axisBorder: {
                show: false,
              },
            },
            legend: {
              show: false,
            },
            dataLabels: {
              enabled: false,
            },
            stroke: {
             
            },
            colors: [$themeColors.primary, $themeColors.warning],
            plotOptions: {
              bar: {
                columnWidth: '17%',
                endingShape: 'rounded',
              },
              distributed: true,
            },
            yaxis: {
              showForNullSeries: false,
              show: true,
              labels: {
                style: {
                  colors: '#6E6B7B',
                  fontSize: '0.86rem',
                  fontFamily: 'Montserrat',
                },
                formatter: function(num) {
                      if (num >= 1000000000000) {
                              return (num / 1000000000000).toFixed(1).replace(/\.0$/, '') + 'T';
                          }
                      if (num >= 1000000000) {
                          return (num / 1000000000).toFixed(1).replace(/\.0$/, '') + 'B';
                      }
                      if (num >= 1000000) {
                          return (num / 1000000).toFixed(1).replace(/\.0$/, '') + 'M';
                      }
                      if (num >= 1000) {
                          return (num / 1000).toFixed(1).replace(/\.0$/, '') + 'K';
                      }
                      return num;
                  }
              },
            },
          },
        },
      }
    },
  }
  </script>
  